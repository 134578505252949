import React, { useState, useEffect } from "react"
import AdminLayout from "../../layouts/AdminLayout"

import { Grid, Paper } from "@material-ui/core"
import { Link } from "gatsby"
import Axios from "axios"
import Pagination from "@material-ui/lab/Pagination"

import host from "../../config/index"

const AccountStatement = ({ location }) => {
  const [client, setClient] = useState({})
  const [accountStatements, setAccountStatements] = useState([])
  const [totalInvestment, setTotalInvestemnt] = useState(0)
  const [totalProfit, setTotalProfit] = useState(0)
  const [page, setPage] = React.useState(1)
  const [totalPage, setTotalPage] = React.useState(0)
  const [textFrom, setTextFrom] = React.useState("")
  const [textTo, setTextTo] = React.useState("")

  const handleChange = (event, value) => {
    setPage(value)
  }
  const getClient = id => {
    let query = ""
    query = query + "?pageNumber=" + page
    query = query + "&pageSize=10"

    if (textFrom != "" && textTo != "") {
      query = query + "&fromDate=" + textFrom + "&toDate=" + textTo
    }

    Axios.get(`${host}/admin/clients/${id}${query}`, {
      headers: { Authorization: `JWT ${localStorage.getItem("token")}` },
    }).then(async ({ data }) => {
      setClient(data.data)
      setTotalInvestemnt(data.data.totalInvestment)
      setTotalProfit(data.data.totalProfit)
      setTotalPage(Math.ceil(data.data.accountStatementCount / 10))
      setAccountStatements(data.data.accountStatements)
    })
  }

  useEffect(() => {
    if (location.state.client) {
      getClient(location.state.client.clientId)
    }
  }, [page, textTo, textFrom])

  const Table = () => {
    return (
      <Paper
        style={{
          background: "rgb(104,102,233)",
          background:
            "linear-gradient(0deg, rgba(104,102,233,1) 0%, rgba(106,165,227,1) 100%)",
          height: "100%",
          padding: 20,
          width: "80%",
          // overflow : "scr"
        }}
      >
        <p style={{ textAlign: "left", fontWeight: "bold" }}>
          Account statement
        </p>

        <Link
          to={`https://docs.google.com/spreadsheets/d/1WF1xhWyyL5t6QgOZNK26MTEC8_LPyLawdJNG0n8Id4M/edit#gid=${client.sheetId}`}
          target="_blank"
        >
          <button
            type="button"
            className="btn btn-light"
            style={{ margin: 20, float: "right" }}
          >
            Edit in sheets
          </button>
        </Link>
        <input
          style={{ margin: 20, float: "right" }}
          placeholder="To (yyyy/mm/dd)"
          onChange={e => {
            setTextTo(e.target.value)
          }}
        />
        <input
          style={{ margin: 20, float: "right" }}
          placeholder="From (yyyy/mm/dd)"
          onChange={e => {
            setTextFrom(e.target.value)
          }}
        />

        <Grid container spacing={3}>
          <Grid item xs={12} lg={1} xl={1} md={1} sm={1}>
            <span style={{ color: "#FFF" }}>Sl.No</span>
          </Grid>
          <Grid item xs={12} lg={3} xl={3} md={3} sm={3}>
            <span style={{ color: "#FFF" }}>Date</span>
          </Grid>
          <Grid item xs={12} lg={3} xl={3} md={3} sm={3}>
            <span style={{ color: "#FFF" }}>Invesment</span>
          </Grid>
          <Grid item xs={12} lg={3} xl={3} md={3} sm={3}>
            <span style={{ color: "#FFF" }}>Profit</span>
          </Grid>
        </Grid>
        <hr style={{ border: "1px solid #fff" }} />

        {accountStatements.map((statement, index) => {
          return (
            <React.Fragment>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={1} xl={1} md={1} sm={1}>
                  <span style={{ color: "#FFF" }}>{index + 1}</span>
                </Grid>
                <Grid item xs={12} lg={3} xl={3} md={3} sm={3}>
                  <span style={{ color: "#FFF" }}>{statement.date}</span>
                </Grid>
                <Grid item xs={12} lg={3} xl={3} md={3} sm={3}>
                  <span style={{ color: "#FFF" }}>{statement.investment}</span>
                </Grid>
                <Grid item xs={12} lg={3} xl={3} md={3} sm={3}>
                  <span style={{ color: "#FFF" }}>
                    {statement.profitPayout}
                  </span>
                </Grid>
              </Grid>
              <hr style={{ border: "1px solid #fff" }} />
            </React.Fragment>
          )
        })}
        <Pagination
          count={totalPage}
          shape="rounded"
          page={page}
          onChange={handleChange}
        />
      </Paper>
    )
  }
  return (
    <AdminLayout>
      <div style={{ padding: 20, width: "100%" }}>
        <Grid container spacing={0} align="center" justify="center">
          <Grid item xs={12}>
            <p
              style={{
                fontSize: 26,
                color: "#6AA5E3",
                fontWeight: "bold",
              }}
            >
              Account Statement
            </p>
            <br />
          </Grid>
          <Grid item xs={8} style={{ padding: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                Name : {client.name}
              </Grid>
              <Grid item xs={6}>
                Client ID :{client.clientId}
              </Grid>
              <Grid item xs={6}>
                Total Investment : {totalInvestment}
              </Grid>
              <Grid item xs={6}>
                Total profit paid : {totalProfit}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className="row">
              {[
                {
                  heading: "Fixed Deposit",
                  component: Table(),
                },
              ].map(slide => {
                return (
                  <Grid
                    item
                    xs={12}
                    lg={12}
                    xl={12}
                    md={12}
                    sm={12}
                    style={{
                      width: "100%",
                      paddingBottom: 100,
                    }}
                  >
                    {slide.component}
                  </Grid>
                )
              })}
            </div>
          </Grid>
        </Grid>
      </div>
    </AdminLayout>
  )
}

export default AccountStatement
